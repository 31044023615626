import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import LandingPage from './LandingPage';
import CheckoutForm from './CheckoutForm';
import SignupForm from './SignupForm';
import Login from './Login';
import ResetPassword from './ResetPassword';
import PriConfig from './PriConfig';
import Dashboard from './Dashboard';
import Settings from './Settings';
import Conectores from './Conectores';
import ConectorDetalhe from './ConectorDetalhe';
import CallbackPage from './CallbackPage';
import ProductList from './ProductList';
import ProductDetails from './ProductDetails';
import AmazonCallbackPage from './AmazonCallbackPage';

// Carregar a chave pública do Stripe
const stripePromise = loadStripe('pk_live_51Q7fDpP1Ty6zbkGvhs0EBmqPgnIsGnxnsrr2AAmCXtjZPaq99328669Gf2alzF7rx43AQqnAUl1MeTeluc7PSdAy00viJrC2nF'); // Substitua pela sua chave pública do Stripe

function App() {
  return (
    <Router>
      <Routes>
        {/* Rota inicial */}
        <Route path="/" element={<LandingPage />} />
        <Route path="/login" element={<Login />} />
        <Route path="/rwmn" element={<ResetPassword />} />
        <Route path="/pri" element={<PriConfig />} />
        <Route path='/dashboard' element={<Dashboard/>}/>
        <Route path='/settings' element={<Settings/>}/>
        <Route path='/conectores' element={<Conectores/>}></Route>
        <Route path="/conectores/:id" element={<ConectorDetalhe />} />
        <Route path='/callback' element={<CallbackPage/>}></Route>
        <Route path='/products' element={<ProductList/>}></Route>
        <Route path="/produto/:id" element={<ProductDetails />} /> {/* Página de detalhes */}
        <Route path='/callback-amazon' element={<AmazonCallbackPage/>}></Route>
        {/* Rota para Checkout, com Elements envolvendo o componente */}
        <Route
          path="/checkout"
          element={
            <Elements stripe={stripePromise}>
              <CheckoutForm />
            </Elements>
          }
        />

        {/* Rota de fallback (opcional) */}
        <Route path="*" element={<h2>Página não encontrada</h2>} />
      </Routes>
    </Router>
  );
}

export default App;
