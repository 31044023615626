import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Bar } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  BarElement,
  CategoryScale,
  LinearScale,
  Tooltip,
  Legend,
} from 'chart.js';
import SidebarMenu from './componente/SidebarMenu';
import './Dashboard.css';

// Registrar os componentes do Chart.js
ChartJS.register(BarElement, CategoryScale, LinearScale, Tooltip, Legend);

const Dashboard = () => {
  const [totalRevenue, setTotalRevenue] = useState(0);
  const [orderCount, setOrderCount] = useState(0);
  const [accountAge, setAccountAge] = useState(0);
  const [displayedRevenue, setDisplayedRevenue] = useState(0);
  const [displayedOrders, setDisplayedOrders] = useState(0);
  const [weeklySales, setWeeklySales] = useState([0, 0, 0, 0, 0, 0, 0]);

  useEffect(() => {
    const fetchDashboardData = async () => {
      try {
        const response = await axios.get('https://vmproj.onrender.com/vendedor/receita-total', {
          params: {
            fkuser: localStorage.getItem('userId'),
          },
        });

        const receitaTotal = parseFloat(response.data.receita_total) || 0;
        const quantidadePedidos = response.data.quantidade_pedidos || 0;
        const idadeConta = response.data.idade_conta || 0;
        const vendasSemanais = response.data.vendas_semanais || [0, 0, 0, 0, 0, 0, 0];

        setTotalRevenue(receitaTotal);
        setOrderCount(quantidadePedidos);
        setAccountAge(idadeConta);
        setWeeklySales(vendasSemanais);
      } catch (error) {
        console.error("Erro ao buscar dados do dashboard:", error);
      }
    };

    fetchDashboardData();
  }, []);

  useEffect(() => {
    const animateCount = (targetValue, setter) => {
      let displayedValue = 0;
      const increment = Math.max(targetValue / 100, 1);
      const interval = setInterval(() => {
        displayedValue += increment;
        if (displayedValue >= targetValue) {
          clearInterval(interval);
          setter(targetValue);
        } else {
          setter(Math.floor(displayedValue));
        }
      }, 20);
    };

    if (totalRevenue > 0) animateCount(totalRevenue, setDisplayedRevenue);
    if (orderCount > 0) animateCount(orderCount, setDisplayedOrders);
  }, [totalRevenue, orderCount]);

  const chartData = {
    labels: ['Sex', 'Sáb', 'Dom', 'Seg', 'Ter', 'Qua', 'Qui'],
    datasets: [
      {
        label: 'Produtos Vendidos',
        data: weeklySales,
        backgroundColor: 'rgba(54, 162, 235, 0.6)',
        borderColor: 'rgba(54, 162, 235, 1)',
        borderWidth: 1,
      },
    ],
  };

  const chartOptions = {
    scales: {
      y: {
        beginAtZero: true,
        ticks: {
          stepSize: 1,
        },
      },
    },
    plugins: {
      legend: {
        display: false,
      },
    },
  };

  return (
    <div className="dashboard-container">
      <SidebarMenu />
      <h1>Bem vindo, Rafael Santos Gomes! 👋</h1>

      <div className="cards-container">
        <div className="card sales-card">
          <h2>Total de vendas</h2>
          <h3>{displayedOrders}</h3>
          <p>Total de produtos vendidos</p>
        </div>

        <div className="card revenue-card">
          <h2>Receita Total</h2>
          <h3>
            {typeof displayedRevenue === 'number'
              ? displayedRevenue.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })
              : 'R$ 0,00'}
          </h3>
          <p>Receita total</p>
        </div>

        <div className="card small-card">
          <h2>Hoje</h2>
          <p>0 produtos vendidos</p>
        </div>

        <div className="card small-card">
          <h2>Contas conectadas</h2>
          <p>1 Total</p>
        </div>

        <div className="card small-card">
          <h2>Tempo de Ativação</h2>
          <p>{accountAge} dias</p>
        </div>
      </div>

      <div className="chart-container">
        <h2>Vendas da Última Semana</h2>
        <Bar data={chartData} options={chartOptions} />
      </div>
    </div>
  );
};

export default Dashboard;
