import React, { useState } from 'react';
import styles from './SidebarMenu.module.css'; // Importando o CSS Module
import { FaHome, FaListAlt, FaMoneyBill, FaCog, FaLightbulb, FaLifeRing, FaDigitalTachograph, FaChartLine } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';

const SidebarMenu = ({ onSidebarToggle }) => { // Recebendo a função onSidebarToggle para controlar o estado
  const [menuExpanded, setMenuExpanded] = useState(true);
  const navigate = useNavigate();

  const handleSettingsClick = () => {
    navigate('/settings'); // Redireciona para a tela de configurações
  };

  const handleProductsClick = () => {
    navigate('/products'); // Redireciona para a tela de produtos
  };

  const handleDashboardClick = () => {
    navigate('/dashboard'); // Redireciona para a tela de produtos
  };

  const toggleSidebar = () => {
    const newMenuState = !menuExpanded;
    setMenuExpanded(newMenuState);
    onSidebarToggle(newMenuState); // Notificando a mudança de estado para o componente pai
  };

  return (
    <div className={`${styles.sidebar} ${menuExpanded ? styles.expanded : styles.collapsed}`}>
      <div className={styles.sidebarHeader}>
        <h2>MarketStream</h2>
        <button onClick={toggleSidebar} className={styles.expandButton}>
          {menuExpanded ? '⮜' : '⮞'}
        </button>
      </div>

      <nav className={styles.menu}>
        <ul>
          <li onClick={handleDashboardClick}><FaChartLine/><span>Dashboard</span></li>
          <li onClick={handleProductsClick}><FaListAlt /><span>Produtos</span></li>
          <li><FaMoneyBill /><span>Suprimentos</span></li>
          <li><FaMoneyBill /><span>Vendas</span></li>
          <li><FaMoneyBill /><span>Finanças</span></li>
        </ul>
      </nav>

      <div className={styles.menuFooter}>
        <ul>
          <li onClick={handleSettingsClick}><FaCog/><span>Configurações</span></li>
          <li><FaLightbulb /><span>Canal de Ideias</span></li>
          <li><FaLifeRing /><span>Suporte</span></li>
        </ul>
      </div>
    </div>
  );
};

export default SidebarMenu;
